import React, { useEffect, useRef, useState } from "react";
import { graphql, StaticQuery, navigate } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  StoreWrapper,
  StoreContainer,
  ItemContainer,
  ItemImage,
  ItemImageContainer,
  ItemTitle,
  ItemPrice,
  ItemDetailContainer,
  ItemDesc,
  FooterContainer,
  StoreSection,
  StoreHeader,
  HandWritten
} from "../components/Store/Store";
import { Grid } from "@material-ui/core";
import { Cart } from "../components/Cart/cart";
import { Footer } from "../components/footer";
import {
  CampaignButton,
  CampaignContainer,
  CampaignImage,
  DisabledCampaignButton
} from "../components/Product/Product";
import getFirebase from "../Firebase/firebase";
import rsccamerahand from "../assets/store/rsccamerahand.png";

export const ProductListing = product => {
  return (
    <GatsbyImage
      image={product.featuredImage.gatsbyImageData}
      alt={product.featuredImage.altText}
    />
  );
};

const AboutPage = ({ data }) => {
  const [url, setUrl] = useState("");
  const [products, setProducts] = useState();
  const siteTitle = data.site.siteMetadata.title;
  let firebase = useRef();

  const handleNavigate = product => {
    navigate(`/store/${product}`);
  };

  const getAndSetCampaignData = () => {
    firebase.current
      .firestore()
      .collection("stores")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          if (doc.data().published) {
            setUrl(doc.data().thumbnail[0]);
            return;
          }
        });
      });
  };

  const compare = (a, b) => {
    if (a.node.title < b.node.title) {
      return -1;
    }
    if (a.node.title > b.node.title) {
      return 1;
    }
    return 0;
  };

  const calculateTimeLeft = () => {
    let difference = +new Date(`8/28/2021 10:30:00`) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      return false;
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    let tempData = { ...data };
    setProducts(tempData.allShopifyProduct.edges.sort(compare));
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    if (typeof window !== undefined) {
      window.addEventListener("resize", appHeight);
    }
    appHeight();
    return window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    if (!getFirebase()) return;
    firebase.current = getFirebase();
    getAndSetCampaignData();
    //eslint-disable-next-line
  }, [firebase]);

  return (
    <>
      <Layout title={siteTitle} siteBgColor={"white"}>
        <SEO
          title="Store"
          keywords={[`wedding`, `sculpture`, `งานแต่งงาน`, `ตู้sculpture`]}
        />
        <CampaignContainer>
          <CampaignImage
            src={url}
            alt="sculpturecampaign"
            disabled={timeLeft !== false}
            onClick={() =>
              timeLeft !== false
                ? console.log("Its not time yet")
                : navigate("/store/sculpture-random-camera")
            }
          />
          {timeLeft !== false ? (
            <DisabledCampaignButton disabled={timeLeft}>
              LAUNCHING ON 28 AUG 2021 - 11:00 AM
            </DisabledCampaignButton>
          ) : (
            <CampaignButton
              onClick={() => navigate("/store/sculpture-random-camera")}
            >
              <HandWritten src={rsccamerahand} alt="handwritten" />
            </CampaignButton>
          )}
        </CampaignContainer>
        <StoreWrapper id="storewrapper">
          <StoreHeader />
          <StoreContainer>
            <StoreSection id="storeRef" />
            <Grid container spacing={0}>
              {products &&
                products.map(item => (
                  <>
                    {(timeLeft === false ||
                      item.node.handle !== "sculpture-random-camera") && (
                      <Grid item sm={6} md={6} lg={4} xl={4}>
                        <ItemContainer>
                          <ItemImageContainer>
                            <ItemImage
                              src={item.node.images[0].src}
                              onClick={() => handleNavigate(item.node.handle)}
                            />
                          </ItemImageContainer>
                          <ItemDetailContainer>
                            <ItemTitle
                              onClick={() => handleNavigate(item.node.handle)}
                            >
                              {item.node.title}
                            </ItemTitle>
                            <ItemPrice>
                              {item.node.priceRangeV2.maxVariantPrice.amount}{" "}
                              THB
                            </ItemPrice>
                          </ItemDetailContainer>
                          <ItemDetailContainer>
                            <ItemDesc
                              onClick={() => handleNavigate(item.node.handle)}
                            >
                              {item.node.description}
                            </ItemDesc>
                          </ItemDetailContainer>
                        </ItemContainer>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
            <FooterContainer>
              <Footer />
            </FooterContainer>
          </StoreContainer>
        </StoreWrapper>
      </Layout>
      <Cart />
    </>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          images {
            src
          }
          status
          title
          handle
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
          }
          description
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
